import ContentBuilder from '@theme/components/utils/ContentBuilder'
import Modal from '@theme/components/utils/Modal'
import gql from 'graphql-tag'
import processContentBuilder from '~/app/processContentBuilder'

export default {
  components: {
    ContentBuilder,
    Modal,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fittingGuide: null,
    }
  },
  methods: {
    hide() {
      this.$refs.modal.hide()
    },
    async loadContent() {
      const result = await this.$apollo.query({
        query: gql`
          query($ids: [Float]!) {
            product(filters: { store_id: $ids }) {
              entities {
                fitting_guide_reference {
                  entity {
                    ... on fitting_guide {
                      content
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          ids: [this.product.id],
        },
      })
      if (result.data.product.entities[0]) {
        if (
          result.data.product.entities[0].fitting_guide_reference &&
          result.data.product.entities[0].fitting_guide_reference[0]
        ) {
          const fittingGuide = result.data.product.entities[0].fitting_guide_reference[0].entity
          if (fittingGuide.content) {
            fittingGuide.content = await processContentBuilder(fittingGuide.content, this.$store.dispatch)
          }
          this.fittingGuide = fittingGuide
        }
      }
    },
    show() {
      this.$refs.modal.show()
      this.loadContent()
    },
  },
}
