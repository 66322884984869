import FittingGuideModal from '~/themes/base/components/product/FittingGuideModal'
import PageFittingGuide from '@theme/components/content/PageFittingGuide'
import gql from 'graphql-tag'
import processContentBuilder from '~/app/processContentBuilder'

export default {
  extends: FittingGuideModal,
  components: {
    PageFittingGuide,
  },
  methods: {
    async loadContent() {
      const result = await this.$apollo.query({
        query: gql`
          query($ids: [Float]!) {
            product(filters: { store_id: $ids }) {
              entities {
                fitting_guide_reference {
                  entity {
                    ... on fitting_guide {
                      fitting_guide_code
                      content
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          ids: [this.product.id],
        },
      })
      if (result.data.product.entities[0]) {
        if (
          result.data.product.entities[0].fitting_guide_reference &&
          result.data.product.entities[0].fitting_guide_reference[0]
        ) {
          const fittingGuide = result.data.product.entities[0].fitting_guide_reference[0].entity
          if (fittingGuide.content) {
            fittingGuide.content = await processContentBuilder(fittingGuide.content, this.$store.dispatch)
          }
          this.fittingGuide = fittingGuide
        }
      }
    },
  },
}
